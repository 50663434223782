<template>
  <v-container>
    <v-container v-if="isRightClickActionSelected">
      <div class="move-copy-notification">
        <div />
        <div>
          {{ rightClickActionTextVerb }} {{ rightClickSelectedEvent ? rightClickSelectedEvent.dedication.sponsor : '' }}. Click a Date to
          {{ rightClickActionText }} to
        </div>
        <v-btn @click="cancelMoveOrCopyMode" color="red darken-1" text>Cancel</v-btn>
      </div>
    </v-container>
    <v-row justify="space-around">
      <v-sheet height="64" width="100%">
        <v-toolbar flat color="white">
          <v-btn v-if="type === 'day'" icon class="mr-4" color="black darken-2" @click="returnToMonthView">
            <v-icon>mdi-keyboard-backspace</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <DedicationsSearch :app="app" @input="onSearchSelect" />
          <v-btn icon class="ma-2" @click="navToPrevMonth">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-col class="d-flex" cols="1">
            <v-select dense v-model="selectedMonth" :items="months" label="Month" @input="setFocusMonth"></v-select>
          </v-col>
          <v-col class="d-flex" cols="1">
            <v-select dense v-model="selectedYear" :items="years" label="Year" @input="setFocusYear"></v-select>
          </v-col>
          <v-btn icon class="ma-2" @click="navToNextMonth">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-toolbar>
      </v-sheet>
    </v-row>
    <v-row justify="space-around">
      <v-sheet width="100%" height="600">
        <v-calendar
          ref="calendar"
          v-model="focus"
          :type="type"
          :events="getEvents"
          :event-overlap-mode="mode"
          :event-overlap-threshold="30"
          :event-color="getEventColor"
          @change="loadEvents"
          @contextmenu:event="rightClickEvent"
          @click:event="openDedication"
          @click:date="leftClick"
          @click:more="viewDay"
        >
          <template v-slot:event="{ eventSummary }">
            <div class="v-event-draggable" v-html="eventSummary()"></div>
          </template>
        </v-calendar>
        <v-menu v-model="selectedOpen" :close-on-content-click="false" :activator="selectedElement" offset-x>
          <v-card color="grey lighten-4" min-width="350px" flat>
            <v-toolbar :color="selectedEvent.color" dark>
              <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn v-if="!selectedEventEditMode" icon @click="openEditDedicationForm">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text v-if="!selectedEventEditMode" v-html="selectedEventDescription"></v-card-text>
            <v-form v-if="selectedEventEditMode" ref="form" class="pa-2">
              <v-text-field readonly v-model="getSelectedDate" label="Date" required></v-text-field>
              <v-text-field v-if="isSelectedDedicationText" v-model="dedicationPayload.sponsor" label="Sponsor" required></v-text-field>
              <v-text-field v-if="isSelectedDedicationText" v-model="dedicationPayload.type" label="Type" required></v-text-field>
              <v-text-field v-if="isSelectedDedicationText" v-model="dedicationPayload.honoree" label="Honoree" required></v-text-field>
              <v-checkbox v-if="isSelectedDedicationText" v-model="dedicationPayload.yahrtzeit" label="Yahrtzeit" required></v-checkbox>
              <v-file-input v-if="isSelectedDedicationAudio" v-model="audioFile" accept="audio/*" label="Select New Audio File"></v-file-input>
            </v-form>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text color="secondary" @click="closeSelectedEvent">Cancel</v-btn>
              <v-btn :loading="editDedicationLoading" v-if="selectedEventEditMode" text color="secondary" @click="editDedication">Edit</v-btn>
              <v-btn v-if="!selectedEventEditMode" text color="secondary" @click="deleteDedication">Delete</v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
        <v-dialog @click:outside="closeAddDedicationForm" v-model="addDedicationFormOpen" width="600">
          <v-card v-if="isReOrderDedications">
            <v-card-title class="headline grey lighten-2" style="padding-bottom: 0">
              <div style="display: flex; align-items: center">
                <div style="flex-basis: 50%">Add Dedication</div>
                <v-tabs v-model="activeTab" background-color="transparent" color="black" style="flex-basis: 50%">
                  <v-tab v-for="tab of tabs" :key="tab.id" :disabled="tab.disabled">{{ tab.name }}</v-tab>
                </v-tabs>
              </div>
            </v-card-title>
            <draggable v-model="dedicationsOfSelectedDateForReOrder" class="pa-7">
              <v-list-item v-for="(dedication, idx) in dedicationsOfSelectedDateForReOrder" :key="idx" class="list-item">
                <v-list-item-content>
                  <v-list-item-title class="list-item-color">{{ dedication.sponsor }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </draggable>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="cancelReOrder">Cancel</v-btn>
              <v-btn color="primary" :loading="reOrderDedicationsLoading" text @click="saveReOrder">Save</v-btn>
            </v-card-actions>
          </v-card>
          <v-card v-else>
            <v-card-title class="headline grey lighten-2" style="padding-bottom: 0">
              <div style="display: flex; align-items: center">
                <div style="flex-basis: 50%">Add Dedication</div>
                <v-tabs v-model="activeTab" background-color="transparent" color="black" style="flex-basis: 50%">
                  <v-tab v-for="tab of tabs" :key="tab.id" :disabled="tab.disabled">{{ tab.name }}</v-tab>
                </v-tabs>
              </div>
            </v-card-title>
            <v-form ref="form" class="pa-7">
              <v-text-field readonly v-model="getSelectedDate" label="Date" required></v-text-field>
              <v-text-field v-if="isTextDedication" v-model="dedicationPayload.sponsor" label="Sponsor" required></v-text-field>
              <v-text-field v-if="isTextDedication" v-model="dedicationPayload.type" label="Type" required></v-text-field>
              <v-text-field v-if="isTextDedication" v-model="dedicationPayload.honoree" label="Honoree" required></v-text-field>
              <v-checkbox v-if="isTextDedication" v-model="dedicationPayload.yahrtzeit" label="Yahrtzeit" required></v-checkbox>
              <v-file-input v-if="isAudioDedication" v-model="audioFile" accept="audio/*" label="Audio File"></v-file-input>
            </v-form>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="closeAddDedicationForm">Close</v-btn>
              <v-btn color="primary" :loading="createDedicationLoading" text @click="createDedication">Create</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dedicationFailedToUpload" max-width="290">
          <v-card>
            <v-card-title class="headline">Failed</v-card-title>
            <v-card-text>Dedication failed to upload. Please try again.</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="dedicationFailedToUpload = false">Close</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="rightClickModalOpen" max-width="150">
          <v-card>
            <v-card-actions>
              <v-btn color="red darken-1" text @click="selectMove">Move</v-btn>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="selectCopy">Copy</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-sheet>
    </v-row>
  </v-container>
</template>

<script>
import {
  CREATE_AUDIO_DEDICATION,
  CREATE_DEDICATION,
  CREATE_PRESIGNED_URL,
  DELETE_AUDIO_DEDICATION,
  DELETE_DEDICATION,
  GET_MONTHLY_AUDIO_DEDICATIONS,
  GET_MONTHLY_DEDICATIONS,
  GET_PRESIGNED_URL,
  IS_CREATE_AUDIO_DEDICATION_COMPLETE,
  IS_LOADED_TO_S3_COMPLETE,
  IS_MONTHLY_AUDIO_DEDICATIONS_LOADED,
  IS_MONTHLY_DEDICATIONS_LOADED,
  LOAD_MONTHLY_AUDIO_DEDICATIONS,
  LOAD_MONTHLY_DEDICATIONS,
  RIGHT_CLICK_COPY,
  RIGHT_CLICK_MOVE,
  UPDATE_AUDIO_DEDICATION,
  UPDATE_DEDICATION,
  UPLOAD_TO_S3,
  GET_PRESIGNED_KEY,
} from '@/services/constants'
import { mapGetters } from 'vuex'
import { parseISO } from 'date-fns'
import draggable from 'vuedraggable'
import DedicationsSearch from '@/components/DedicationsSearch'

const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

export default {
  name: 'Dedications',
  components: { draggable, DedicationsSearch },
  props: {
    app: {
      type: String,
      default: 'rcd',
    },
  },
  data: () => ({
    type: 'month',
    focus: '',
    mode: 'stack',
    value: '',
    colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
    selectedOpen: false,
    selectedEventEditMode: false,
    selectedEvent: {},
    selectedElement: null,
    addDedicationFormOpen: false,
    selectedDate: {
      year: '',
      month: '',
      day: '',
    },
    dedicationPayload: {
      sponsor: '',
      honoree: '',
      yahrtzeit: false,
      type: '',
    },
    dedicationsOfSelectedDateForReOrder: [],
    audioFile: [],
    createDedicationLoading: false,
    editDedicationLoading: false,
    reOrderDedicationsLoading: false,
    activeTab: 0,
    dedicationFailedToUpload: false,
    rightClickModalOpen: false,
    rightClickActionType: '',
    rightClickSelectedEvent: null,
    dateOnInitialPageLoad: null,
    autoRefreshIntervalTimer: '',
    selectedMonth: new Date().toLocaleString('default', { month: 'long' }),
    selectedYear: new Date().getFullYear(),
  }),
  computed: {
    ...mapGetters([
      GET_MONTHLY_DEDICATIONS,
      IS_MONTHLY_DEDICATIONS_LOADED,
      GET_PRESIGNED_URL,
      GET_MONTHLY_AUDIO_DEDICATIONS,
      IS_MONTHLY_AUDIO_DEDICATIONS_LOADED,
      IS_CREATE_AUDIO_DEDICATION_COMPLETE,
      IS_LOADED_TO_S3_COMPLETE,
      GET_PRESIGNED_KEY,
    ]),
    isTextDedication() {
      return this.activeTab === 0
    },
    isAudioDedication() {
      return this.activeTab === 1
    },
    isReOrderDedications() {
      return this.activeTab === 2
    },
    getEvents() {
      return this.getTextDedications.concat(this.getAudioDedications)
    },
    getTextDedications() {
      if (this[IS_MONTHLY_DEDICATIONS_LOADED]) {
        return this[GET_MONTHLY_DEDICATIONS].filter((dedication) => dedication.app === this.app)
          .map((dedication) => {
            dedication.format = 'text'
            return {
              name: dedication.sponsor,
              start: parseISO(dedication.date),
              end: parseISO(dedication.date),
              color: 'blue',
              timed: false,
              dedication: dedication,
            }
          })
          .sort((a, b) => {
            if (a?.dedication.rank < b?.dedication.rank) {
              return -1
            } else if (a?.dedication.rank > b?.dedication.rank) {
              return 1
            } else {
              return 0
            }
          })
      } else {
        return []
      }
    },
    getAudioDedications() {
      if (this[IS_MONTHLY_AUDIO_DEDICATIONS_LOADED]) {
        return this[GET_MONTHLY_AUDIO_DEDICATIONS].filter((dedication) => dedication.app === this.app)
          .filter((dedication) => {
            return dedication?.url
          })
          .map((audioDedication) => {
            audioDedication.format = 'audio'
            return {
              id: audioDedication.id,
              name: audioDedication?.filename,
              start: parseISO(audioDedication.date),
              end: parseISO(audioDedication.date),
              color: 'green',
              timed: false,
              dedication: audioDedication,
              url: audioDedication?.url,
            }
          })
      } else {
        return []
      }
    },
    getSelectedDate() {
      let year = this.selectedDate.year
      let month = this.selectedDate.month
      let day = this.selectedDate.day
      return `${year}-${month}-${day}`
    },
    selectedEventDescription() {
      if (Object.keys(this.selectedEvent).length) {
        let dedication = this.selectedEvent?.dedication
        let date = `<b>Date</b>: ${dedication.date}<br/>`
        let name = ` <b>Name</b>: <a href="${dedication.url}">${this.selectedEvent.name}</a><br/>`
        let otherTextDedicationDetails = `<b>Sponsor</b>: ${dedication.sponsor}<br/>
                        <b>Type</b>: ${dedication.type}<br/>
                        <b>Honoree</b>: ${dedication.honoree}<br/>
                        <b>Yahrtzeit</b>: ${dedication.yahrtzeit}`
        return dedication.format === 'text' ? date + otherTextDedicationDetails : date + name
      } else {
        return ''
      }
    },
    presignedObjectPayload() {
      return {
        filename: this.audioFile.name,
        contentType: this.audioFile.type,
        size: this.audioFile.size,
      }
    },
    uploadToS3Payload() {
      return {
        url: this[GET_PRESIGNED_URL],
        file: this.audioFile,
      }
    },
    audioTabDisabled() {
      return this[GET_MONTHLY_AUDIO_DEDICATIONS].filter((dedication) => dedication.app === this.app).some((audioDedication) => {
        let date = parseISO(audioDedication.date)
        return (
          Number(date.getMonth() + 1) === Number(this.selectedDate.month) &&
          Number(date.getFullYear()) === Number(this.selectedDate.year) &&
          Number(date.getDate()) === Number(this.selectedDate.day)
        )
      })
    },
    dedicationsFromSelectedDate() {
      return this[GET_MONTHLY_DEDICATIONS].filter((dedication) => dedication.app === this.app)
        .filter((dedication) => {
          const dedicationDate = dedication.date.split(' ')[0]
          return dedicationDate === `${this.selectedDate.year}-${this.selectedDate.month}-${this.selectedDate.day}`
        })
        .sort((a, b) => {
          if (a.rank < b.rank) {
            return -1
          } else if (a.rank > b.rank) {
            return 1
          } else {
            return 0
          }
        })
    },
    reOrderTabDisabled() {
      return this.dedicationsFromSelectedDate.length === 0
    },
    tabs() {
      return [
        { id: 0, name: 'Text', disabled: false },
        { id: 1, name: 'Audio', disabled: this.audioTabDisabled },
        { id: 2, name: 'Re-Order', disabled: this.reOrderTabDisabled },
      ]
    },
    isSelectedDedicationText() {
      return this.selectedEvent?.dedication && this.selectedEvent?.dedication.format === 'text'
    },
    isSelectedDedicationAudio() {
      return this.selectedEvent?.dedication && this.selectedEvent?.dedication.format === 'audio'
    },
    isRightClickSelectedEventDedicationText() {
      return this.rightClickSelectedEvent?.dedication && this.rightClickSelectedEvent?.dedication.format === 'text'
    },
    isRightClickSelectedEventDedicationAudio() {
      return this.rightClickSelectedEvent?.dedication && this.rightClickSelectedEvent?.dedication.format === 'audio'
    },
    createRightClickDedicationPayload() {
      return {
        sponsor: this.rightClickSelectedEvent?.dedication.sponsor,
        honoree: this.rightClickSelectedEvent?.dedication.honoree,
        yahrtzeit: this.rightClickSelectedEvent?.dedication.yahrtzeit,
        type: this.rightClickSelectedEvent?.dedication.type,
        date: this.getSelectedDate,
        app: this.app,
      }
    },
    createRightClickAudioDedicationPayload() {
      return {
        date: this.getSelectedDate,
        key: this.rightClickSelectedEvent?.dedication.url.split('/').at(-1),
      }
    },
    isRightClickActionSelected() {
      return this.rightClickActionType === RIGHT_CLICK_COPY || this.rightClickActionType === RIGHT_CLICK_MOVE
    },
    rightClickActionTextVerb() {
      if (this.rightClickActionType === RIGHT_CLICK_COPY) {
        return 'Copying'
      } else if (this.rightClickActionType === RIGHT_CLICK_MOVE) {
        return 'Moving'
      } else {
        return ''
      }
    },
    rightClickActionText() {
      if (this.rightClickActionType === RIGHT_CLICK_COPY) {
        return 'Copy'
      } else if (this.rightClickActionType === RIGHT_CLICK_MOVE) {
        return 'Move'
      } else {
        return ''
      }
    },
    months() {
      return MONTHS
    },
    years() {
      const d = new Date()
      const yearValues = []
      let earliestYear = 2015
      let lastYear = d.getFullYear() + 10
      while (earliestYear <= lastYear) {
        yearValues.push(earliestYear)
        earliestYear += 1
      }
      return yearValues
    },
  },
  created() {
    this.dateOnInitialPageLoad = new Date()
    this.autoRefreshIntervalTimer = setInterval(this.refreshPage, 10000)
  },
  methods: {
    loadEvents({ start }) {
      let date = { year: start.year, month: start.month }
      this.$store.dispatch(LOAD_MONTHLY_DEDICATIONS, date)
      this.$store.dispatch(LOAD_MONTHLY_AUDIO_DEDICATIONS, date)
    },
    getEventColor(event) {
      return event.color
    },
    setSelectedDate(year, month, day) {
      month = month >= 10 ? month : `0${month}`
      day = day >= 10 ? day : `0${day}`
      this.selectedDate = {
        year,
        month,
        day,
      }
    },
    leftClick(event) {
      if (this.isRightClickActionSelected) {
        this.rightClickDate(event)
      } else {
        this.openAddDedicationForm(event)
      }
    },
    openAddDedicationForm(event) {
      this.resetDedicationPayload()
      this.audioFile = []
      this.setSelectedDate(event.year, event.month, event.day)
      this.selectedOpen = false
      const open = () => {
        setTimeout(() => (this.addDedicationFormOpen = true), 10)
      }
      if (this.addDedicationFormOpen) {
        this.addDedicationFormOpen = false
        setTimeout(open, 10)
      } else {
        open()
      }
      this.dedicationsOfSelectedDateForReOrder = this.dedicationsFromSelectedDate.slice()
    },
    openDedication({ nativeEvent, event }) {
      this.addDedicationFormOpen = false
      let date = parseISO(event?.dedication.date)
      this.setSelectedDate(date.getFullYear(), date.getMonth() + 1, date.getDate())
      const open = () => {
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        setTimeout(() => (this.selectedOpen = true), 10)
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        setTimeout(open, 10)
      } else {
        open()
      }
      nativeEvent.stopPropagation()
    },
    createDedication() {
      if (this.isTextDedication) {
        this.createTextDedication()
      }
      if (this.isAudioDedication) {
        this.createAudioDedication()
      }
    },
    createTextDedication() {
      this.createDedicationLoading = true
      let year = this.selectedDate.year
      let month = this.selectedDate.month
      let dedication = this.createDedicationPayload()
      this.$store
        .dispatch(CREATE_DEDICATION, dedication)
        .then(() => {
          let date = { year: year, month: month }
          return this.$store.dispatch(LOAD_MONTHLY_DEDICATIONS, date)
        })
        .then(() => {
          this.closeAddDedicationForm()
        })
    },
    createDedicationPayload() {
      let dedicationPayload = this.dedicationPayload
      dedicationPayload.date = this.getSelectedDate
      return dedicationPayload
    },
    createAudioDedicationPayload() {
      return {
        date: this.getSelectedDate,
        key: this[GET_PRESIGNED_KEY],
      }
    },
    resetDedicationPayload() {
      this.dedicationPayload = {
        sponsor: '',
        honoree: '',
        yahrtzeit: false,
        type: '',
        app: this.app,
      }
    },
    createAudioDedication() {
      this.createDedicationLoading = true
      this.$store
        .dispatch(CREATE_PRESIGNED_URL, this.presignedObjectPayload)
        .then(() => {
          return this.$store.dispatch(UPLOAD_TO_S3, this.uploadToS3Payload)
        })
        .then(() => {
          if (!this[IS_LOADED_TO_S3_COMPLETE]) {
            throw new Error()
          }
          return this.$store.dispatch(CREATE_AUDIO_DEDICATION, this.createAudioDedicationPayload())
        })
        .then(() => {
          let year = this.selectedDate.year
          let month = this.selectedDate.month
          let date = { year: year, month: month }
          return this.$store.dispatch(LOAD_MONTHLY_AUDIO_DEDICATIONS, date)
        })
        .then(() => {
          this.closeAddDedicationForm()
        })
        .catch(() => {
          this.createDedicationLoading = false
          this.dedicationFailedToUpload = true
        })
    },
    openEditDedicationForm() {
      this.selectedOpen = false
      this.$nextTick(() => {
        this.selectedEventEditMode = true
        this.selectedOpen = true
      })
      this.dedicationPayload.sponsor = this.selectedEvent.dedication.sponsor
      this.dedicationPayload.honoree = this.selectedEvent.dedication.honoree
      this.dedicationPayload.yahrtzeit = this.selectedEvent.dedication.yahrtzeit
      this.dedicationPayload.type = this.selectedEvent.dedication.type
      this.dedicationPayload.app = this.app
    },
    deleteDedication() {
      if (this.isSelectedDedicationText) {
        this.deleteTextDedication()
      }
      if (this.isSelectedDedicationAudio) {
        this.deleteAudioDedication()
      }
    },
    deleteAudioDedication() {
      let year = this.selectedDate.year
      let month = this.selectedDate.month
      this.$store.dispatch(DELETE_AUDIO_DEDICATION, this.selectedEvent.dedication.id).then(() => {
        this.selectedEvent = {}
        this.selectedOpen = false
        this.$store.dispatch(LOAD_MONTHLY_AUDIO_DEDICATIONS, { year: year, month: month })
      })
    },
    deleteTextDedication() {
      let year = this.selectedDate.year
      let month = this.selectedDate.month
      this.$store.dispatch(DELETE_DEDICATION, this.selectedEvent.dedication.id).then(() => {
        this.selectedEvent = {}
        this.selectedOpen = false
        this.$store.dispatch(LOAD_MONTHLY_DEDICATIONS, { year: year, month: month })
      })
    },
    editDedication() {
      this.editDedicationLoading = true
      if (this.isSelectedDedicationText) {
        this.editTextDedication()
      }
      if (this.isSelectedDedicationAudio) {
        this.editAudioDedication()
      }
    },
    editTextDedication() {
      let payload = { id: this.selectedEvent.dedication.id, payload: this.createDedicationPayload() }
      let year = this.selectedDate.year
      let month = this.selectedDate.month
      this.$store
        .dispatch(UPDATE_DEDICATION, payload)
        .then(() => {
          return this.$store.dispatch(LOAD_MONTHLY_DEDICATIONS, { year: year, month: month })
        })
        .then(() => {
          this.selectedOpen = false
          this.selectedEvent = {}
          this.selectedEventEditMode = false
          this.editDedicationLoading = false
        })
    },
    editAudioDedication() {
      this.$store
        .dispatch(CREATE_PRESIGNED_URL, this.presignedObjectPayload)
        .then(() => {
          return this.$store.dispatch(UPLOAD_TO_S3, this.uploadToS3Payload)
        })
        .then(() => {
          let payload = { id: this.selectedEvent.dedication.id, payload: this.createAudioDedicationPayload() }
          return this.$store.dispatch(UPDATE_AUDIO_DEDICATION, payload)
        })
        .then(() => {
          let year = this.selectedDate.year
          let month = this.selectedDate.month
          let date = { year: year, month: month }
          return this.$store.dispatch(LOAD_MONTHLY_AUDIO_DEDICATIONS, date)
        })
        .then(() => {
          this.selectedOpen = false
          this.selectedEvent = {}
          this.audioFile = []
          this.selectedEventEditMode = false
          this.editDedicationLoading = false
        })
    },
    closeSelectedEvent() {
      if (this.selectedEventEditMode) {
        this.selectedEventEditMode = false
        this.selectedOpen = false
        this.$nextTick(() => {
          this.selectedOpen = true
        })
      } else {
        this.selectedOpen = false
      }
    },
    closeAddDedicationForm() {
      this.addDedicationFormOpen = false
      this.$nextTick(() => {
        this.createDedicationLoading = false
        this.reOrderDedicationsLoading = false
        this.activeTab = 0
      })
    },
    viewDay({ date }) {
      this.focus = date
      this.type = 'day'
    },
    returnToMonthView() {
      this.focus = ''
      this.type = 'month'
    },
    rightClickEvent({ nativeEvent, event }) {
      nativeEvent.preventDefault()
      this.rightClickModalOpen = true
      this.rightClickSelectedEvent = event
    },
    async rightClickDate(event) {
      if (this.rightClickSelectedEvent !== null) {
        this.setSelectedDate(event.year, event.month, event.day)
        this.rightClickCopyEventToNewDay()
        if (this.rightClickActionType === RIGHT_CLICK_MOVE) {
          await this.rightClickDeleteEventFromCurrentDay()
          this.cancelMoveOrCopyMode()
        } else {
          this.cancelMoveOrCopyMode()
        }
      }
    },
    selectCopy() {
      this.rightClickActionType = RIGHT_CLICK_COPY
      this.rightClickModalOpen = false
    },
    selectMove() {
      this.rightClickActionType = RIGHT_CLICK_MOVE
      this.rightClickModalOpen = false
    },
    rightClickCopyEventToNewDay() {
      if (this.isRightClickSelectedEventDedicationText) {
        this.rightClickCopyTextDedication()
      }
      if (this.isRightClickSelectedEventDedicationAudio) {
        this.rightClickCopyAudioDedication()
      }
    },
    rightClickCopyTextDedication() {
      let year = this.selectedDate.year
      let month = this.selectedDate.month
      let dedication = this.createRightClickDedicationPayload
      this.$store.dispatch(CREATE_DEDICATION, dedication).then(() => {
        let date = { year: year, month: month }
        return this.$store.dispatch(LOAD_MONTHLY_DEDICATIONS, date)
      })
    },
    rightClickCopyAudioDedication() {
      this.$store.dispatch(CREATE_AUDIO_DEDICATION, this.createRightClickAudioDedicationPayload).then(() => {
        let year = this.selectedDate.year
        let month = this.selectedDate.month
        let date = { year: year, month: month }
        return this.$store.dispatch(LOAD_MONTHLY_AUDIO_DEDICATIONS, date)
      })
    },
    async rightClickDeleteEventFromCurrentDay() {
      if (this.isRightClickSelectedEventDedicationText) {
        await this.rightClickDeleteTextDedication()
      }
      if (this.isRightClickSelectedEventDedicationAudio) {
        await this.rightClickDeleteAudioDedication()
      }
    },
    async rightClickDeleteTextDedication() {
      let year = this.selectedDate.year
      let month = this.selectedDate.month
      await this.$store.dispatch(DELETE_DEDICATION, this.rightClickSelectedEvent?.dedication.id).then(() => {
        this.$store.dispatch(LOAD_MONTHLY_DEDICATIONS, { year: year, month: month })
      })
    },
    async rightClickDeleteAudioDedication() {
      let year = this.selectedDate.year
      let month = this.selectedDate.month
      this.$store.dispatch(DELETE_AUDIO_DEDICATION, this.rightClickSelectedEvent?.dedication.id).then(() => {
        this.$store.dispatch(LOAD_MONTHLY_AUDIO_DEDICATIONS, { year: year, month: month })
      })
    },
    saveReOrder() {
      this.reOrderDedicationsLoading = true
      let year = this.selectedDate.year
      let month = this.selectedDate.month
      const payloads = this.dedicationsOfSelectedDateForReOrder.map((dedication, index) => {
        dedication.rank = index + 1
        return {
          id: dedication.id,
          payload: dedication,
        }
      })
      const promises = payloads.map((payload) => {
        return this.$store.dispatch(UPDATE_DEDICATION, payload)
      })

      Promise.all(promises)
        .then(() => {
          return this.$store.dispatch(LOAD_MONTHLY_DEDICATIONS, { year: year, month: month })
        })
        .then(() => {
          this.selectedOpen = false
          this.selectedEvent = {}
          this.selectedEventEditMode = false
          this.editDedicationLoading = false
          this.addDedicationFormOpen = false
          this.$nextTick(() => {
            this.createDedicationLoading = false
            this.reOrderDedicationsLoading = false
            this.dedicationsOfSelectedDateForReOrder = []
            this.activeTab = 0
          })
        })
    },
    cancelReOrder() {
      this.addDedicationFormOpen = false
      this.$nextTick(() => {
        this.createDedicationLoading = false
        this.reOrderDedicationsLoading = false
        this.dedicationsOfSelectedDateForReOrder = []
        this.activeTab = 0
      })
    },
    refreshPage() {
      const newDate = new Date()
      if (this.dateOnInitialPageLoad.getDay() !== newDate.getDay()) {
        this.dateOnInitialPageLoad = newDate
        this.$forceUpdate()
      }
    },
    cancelAutoUpdate() {
      clearInterval(this.autoRefreshIntervalTimer)
    },
    cancelMoveOrCopyMode() {
      this.rightClickModalOpen = false
      this.rightClickActionType = ''
      this.rightClickSelectedEvent = null
    },
    onSearchSelect(item) {
      this.focus = item.date
      const d = new Date(item.date)
      this.setDropdownDate(d)
    },
    setFocusMonth(month) {
      const d = this.focus ? new Date(this.focus) : new Date()
      const monthNum = new Date(Date.parse(month + ' 1, 2022')).getMonth()
      this.focus = new Date(d.getFullYear(), monthNum, d.getDate())
    },
    setFocusYear(year) {
      console.log('year ', this.focus)
      const d = this.focus ? new Date(this.focus) : new Date()
      console.log({ d })
      this.focus = new Date(year, d.getMonth(), d.getDate())
    },
    navToNextMonth() {
      const d = this.focus ? new Date(this.focus) : new Date()
      const monthNum = d.getMonth() + 1
      const date = new Date(d.getFullYear(), monthNum, d.getDate())
      this.focus = date
      this.setDropdownDate(date)
    },
    navToPrevMonth() {
      const d = this.focus ? new Date(this.focus) : new Date()
      const monthNum = d.getMonth() - 1
      const date = new Date(d.getFullYear(), monthNum, d.getDate())
      this.focus = date
      this.setDropdownDate(date)
    },
    setDropdownDate(d) {
      this.selectedMonth = d.toLocaleString('default', { month: 'long' })
      this.selectedYear = d.getFullYear()
    },
  },
  beforeDestroy() {
    this.cancelAutoUpdate()
  },
}
</script>

<style lang="scss">
.center-add-dedication-form {
  position: fixed;
  top: 25%;
  left: 25%;
}

.v-event-draggable .center-add-dedication-form {
  position: fixed;
  top: 25%;
  left: 25%;
}

.v-event-draggable {
  padding-left: 6px;
}

.move-copy-notification {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid red;
  border-radius: 10px;
}

.list-item {
  cursor: pointer;
  margin: 4px 0;
  background-color: #2196f3;
  border-radius: 10px;
}

.list-item-color {
  color: white;
}
</style>
